import { Component, OnInit } from '@angular/core';
import { TicketActivity } from 'app/evo/models/ticketactivity';
import { EvoTicketActivityService } from 'app/evo/services/evo-ticket-activity.service';
import { InformationService } from 'app/evo/services/information.service';
import * as snippet from 'app/evo/components/layout/modals.snippetcode';
import { NgbCalendar, NgbDateParserFormatter, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'app/evo/services/helper.service';
import { Partner } from 'app/evo/models/partner';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { User } from 'app/evo/models/user';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { EvoEffortService } from 'app/evo/services/evo-effort.service';
import { Effort } from 'app/evo/models/effort';
import { EffortStatus } from 'app/evo/models/effortstatus';
import { Observable, of } from 'rxjs';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { DatePipe } from '@angular/common';
import { BillType } from 'app/evo/models/billtype';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { Module } from 'app/evo/models/module';

@Component({
  selector: 'app-evo-ticket-activity-list',
  templateUrl: './evo-ticket-activity-list.component.html',
  styles: [
  ]
})
export class EvoTicketActivityListComponent implements OnInit {

  public today = this.calendar.getToday();
  userRole: string;
  dashboardFilter: any;

  onSelectBillTypeId() {
    if (this.userRole == "Admin" && this.selectedActivities.length > 0 && this.selectedBillTypeId != 0) {
      this.selectedActivities.forEach((e: TicketActivity) => {
        e.updatedBy = this.myId;
        e.approvementId = 0;
        e.deletedBy = 0;
        e.billTypeId = this.selectedBillTypeId;
      })
      this.ticketActivityService.UpdateMultipleActivity(this.selectedActivities).subscribe((res: any) => {
        this.informationService.update(res.message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
    }
  }

  selectedActivities = []

  // onCheckboxChangeFn(event: any, item: any) {
  //   if (event.target.checked) {
  //     this.selectedActivities.push(item)
  //   }
  //   else {
  //     var index = this.selectedActivities.findIndex(p => p.id == item.id)
  //     this.selectedActivities.splice(index, 1)
  //   }
  // }

  // selectAllRows() {
  //   // Tüm satırları seçmek için checkbox durumunu güncelle
  //   const allRowsSelected = this.ticketActivities.every(activity => this.selectedActivities.includes(activity));
  //   if (!allRowsSelected) {
  //     // Eğer tüm satırlar seçili değilse, tüm satırları seç
  //     this.selectedActivities = [...this.ticketActivities];
  //   } else {
  //     // Eğer tüm satırlar zaten seçiliyse, seçimleri kaldır
  //     this.selectedActivities = [];
  //   }
  //   const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  //   checkboxes.forEach((checkbox: any) => {
  //     checkbox.checked = !allRowsSelected;
  //   });
  // }
  onCheckboxChange(event: any, row: any) {
    const seciliMasraf = this.ticketActivities[row];
    if (!this.selectedActivities.includes(seciliMasraf)) {
      this.selectedActivities.push(seciliMasraf);
    }
    else {
      // Masraf zaten seçiliyse, seçili olanlar listesinden kaldırın
      this.selectedActivities = this.selectedActivities.filter(masraf => masraf !== seciliMasraf);
    }
  }

  // ngx-datatable içerisindeki tüm satırları bir liste içerisine alıyor veya çıkarıyor
  onSelect({ selected }) {
    
    this.selectedActivities.splice(0, this.selectedActivities.length);
    this.selectedActivities.push(...selected);
  }

  fromDate: Date;
  fromDateForEffort: Date;
  toDate: Date;
  toDateForEffort: Date;

  public hoveredDate: any = null;
  public fromDateExample: any = null;
  public toDateExample: any = null

  public hoveredDateForEffort: any | null = null;
  public fromDateExampleForEffort: any = null;
  public toDateExampleForEffort: any = null

  public customFooterDPdata: NgbDateStruct;
  public basicTP = { hour: 13, minute: 30 };
  public selectCustomHeaderFooterSelected = [];

  public selectMultiSelectedStatus;
  public selectMultiStatuses: Observable<any[]>;

  firstLevelRestriction: boolean;
  secondLevelRestriction: boolean;
  firstLevelRestrictionForEffort: boolean;
  secondLevelRestrictionForEffort: boolean;

  constructor(
    private ticketActivityService: EvoTicketActivityService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private partnerUserService: EvoPartnerUserService,
    private effortService: EvoEffortService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,

    private informationService: InformationService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private errorService: ErrorService,
    private moduleService: EvoModuleService
  ) { }

  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true
  };

  public basicSelectedOption: number = 10;
  public moduleSelectOptions : number = 10;
  public _snippetCodeRangeSelectionDP = snippet.snippetCodeRangeSelectionDP;

  users: User[] = [];
  efforts: Effort[] = [];
  partners: Partner[] = [];
  permissions: Permission[] = [];
  ticketActivities: TicketActivity[];
  permissionUsers: PermissionUser[] = [];
  modules: Module[] = [];

  myId: number;
  userId: number = 0;
  partnerId: number = 0;
  filterText: string = "";
  filterTextForEffort: string = "";

  deletedTicketActivityId: number;
  deletedEffortId: number;

  selectedModules : Module[]= [];
  selectedUsers: User[] = [];
  selectedUsersForEffort: User[] = [];
  selectedPartners: Partner[] = [];
  selectedPartnersForEffort: Partner[] = [];
  selectedEffortStatuses: EffortStatus[] = [];
  selectedBillType: BillType[] = [];
  selectedBillTypeId: number = 0;
  StartDate: Date;
  StarDateForEffort: Date;
  EndDate: Date;
  EndDateForEffort: Date;

  effortStatuses: EffortStatus[] = [
    { id: 1, name: "Onaylandı" },
    { id: 2, name: "Onay Bekleniyor" },
    { id: 3, name: "Reddedildi" },
  ];

  billTypes: BillType[] = [
    { id: 4, name: "Faturalanmadı" },
    { id: 5, name: "Faturalandı" },
    { id: 6, name: "Feragat Edildi" }
  ];
  
  ngOnInit(): void {
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.partnerService.getList().subscribe((items: any) => {
      this.partners = items.data;
    });
    this.userService.getHaveActivityUsers().subscribe(items => {
      this.users = items.data;
    });
    
    this.selectMultiStatuses = of(this.effortStatuses);
    //this.tableByFiltersIdForEffort();
    this.getPermissions();
    this.moduleService.getList().subscribe((res: any) => {
      this.modules = res.data
    })

    const storedFilter = localStorage.getItem('filter');

    if (storedFilter) {
      this.filterFromDashboard(storedFilter);
      localStorage.removeItem('filter');
    }
    this.tableByFiltersId()
  }

  addActivityId(id: number, userid: number) {
    localStorage.setItem("TicketActivityId", id.toString())
    localStorage.setItem("TicketActivityUserId", userid.toString())
  }
  list() {
    let userId = this.decodeService.getUserId();
    if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.ticketActivityService.getList().subscribe((items: any) => {
        this.ticketActivities = items.data;
        this.calculateUserHours(this.users, items.data)
        this.calculatePartnerHours(this.partners, this.ticketActivities);
        this.calculateModuleHours(this.modules,this.ticketActivities);
        this.informationService.list(items.message)
      }, (err: any) => {
        this.errorService.errorHandler(err);
      });

    }

    if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.partnerUserService.getByUserId(userId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerTicketActivityList(res.data.partnerId).subscribe((res: any) => {
          this.ticketActivities = res.data;
          this.informationService.list(res.message)
        }, (err: any) => {
          this.errorService.errorHandler(err);
        })

      });
    }
    if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.ticketActivityService.restrictedTicketActivities(this.myId).subscribe((items: any) => {
        this.ticketActivities = items.data;
        this.informationService.list(items.message)
      }, (err: any) => {
        this.errorService.errorHandler(err);
      });

    }
    if (this.secondLevelRestriction) {
      this.ticketActivityService.getUsersTicketActivities(this.myId).subscribe((items: any) => {
        this.ticketActivities = items.data;
        this.informationService.list(items.message)
      }, (err: any) => {
        this.errorService.errorHandler(err);
      });
    }
  }
  filterFromDashboard(storedFilter: any) {
    this.dashboardFilter = JSON.parse(storedFilter);
    const selectedStatus = this.effortStatuses.find(status => status.id === this.dashboardFilter.statusId);
    this.selectMultiSelectedStatus = [{ name: selectedStatus.name }];
    this.selectedEffortStatuses = [this.dashboardFilter.statusId];
    this.tableByFiltersIdForEffort();
  }


  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
      this.checkPermissionUsers();
    });
  }

  checkPermissionUsers() {
    this.permissionUserService.getPermissionUserListByUserId(this.myId).subscribe((items: any) => {
      this.permissionUsers = items.data;

      if (this.permissionUsers.some(user => user.permissionId === 5)) {
        this.firstLevelRestriction = true;
      } else {
        this.firstLevelRestriction = false;
      }

      if (this.permissionUsers.some(user => user.permissionId === 6)) {
        this.secondLevelRestriction = true;
      } else {
        this.secondLevelRestriction = false;
      }

      if (this.permissionUsers.some(user => user.permissionId === 8)) {
        this.firstLevelRestrictionForEffort = true;
      } else {
        this.firstLevelRestrictionForEffort = false;
      }

      if (this.permissionUsers.some(user => user.permissionId === 9)) {
        this.secondLevelRestrictionForEffort = true;
      } else {
        this.secondLevelRestrictionForEffort = false;
      }
      this.list();
      this.tableByFiltersIdForEffort();
    });
  }

  deleteActivity(id: number) {
    this.ticketActivityService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;
      item.data.approvementId = 0;
      this.ticketActivityService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.tableByFiltersId()
        }, 500);
      });
    });
  }
  deleteEffort(id: number) {
    this.effortService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.effortService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    });
  }
  userHours = [];
  partnerHours = []
  modulesHours = []
  // bu method danışmanların toplam çalışma saatlarini listeliyor
  calculateUserHours(users: any[], activities: any[]): void {
    const userHoursMap = new Map<number, number>();

    activities.forEach(activity => {
      const totalHours = userHoursMap.get(activity.userId) || 0;
      userHoursMap.set(activity.userId, totalHours + activity.time);
    });

    this.userHours = users.map(user => ({
      userName: user.userName,
      name: user.firstName,
      email: user.mailAddress,
      title: user.title,
      totalHours: userHoursMap.get(user.id) || 0
    }));
  }
  calculatePartnerHours(partners: any[], activities: any[]): void {
    const partnerHoursMap = new Map<number, number>();

    activities.forEach(activity => {
      const totalHours = partnerHoursMap.get(activity.partnerId) || 0;
      partnerHoursMap.set(activity.partnerId, totalHours + activity.time);
    });
    this.partnerHours = partners.map(partner => ({
      name: partner.name,
      title: partner.title,
      email: partner.mailAddress,
      totalHours: partnerHoursMap.get(partner.id) || 0
    }))
  }
  calculateModuleHours(modules: any[], activities: any[]): void {
    const moduleHoursMap = new Map<number, number>();
    activities.forEach(activity => {
      const totalHours = moduleHoursMap.get(activity.moduleId) || 0;
      moduleHoursMap.set(activity.moduleId, totalHours + activity.time);
    });
    this.modulesHours = modules.map(module => ({
      name: module.name,
      title: module.title,
      totalHours: moduleHoursMap.get(module.id) || 0
    }))
  }

  tableByFiltersId() {
    var userstate = false
    var partnerstate = false
    var modulestate = false
    let filterFunction = (filteredItems: any) => {
      if (this.selectedUsers.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        userstate = true
      }

      if (this.selectedPartners.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedPartners.includes(item.partnerId));
        partnerstate = true
      }

      if (this.selectedBillType.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedBillType.includes(item.billTypeId));
      }
      if(this.selectedModules.length > 0){
        filteredItems = filteredItems.filter(item => this.selectedModules.includes(item.moduleId))
        modulestate = true
      }

      if (this.fromDate || this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDate) &&
          new Date(item.startDate) <= new Date(this.toDate)
        );
      }
      this.ticketActivities = filteredItems;
      this.calculateUserHours(this.users, this.ticketActivities)
      if (userstate) {
        this.userHours = this.userHours.filter(z => z.totalHours > 0)
      }
      this.calculatePartnerHours(this.partners, this.ticketActivities);
      if (partnerstate) {
        this.partnerHours = this.partnerHours.filter(x => x.totalHours > 0)
      }
      this.calculateModuleHours(this.modules,this.ticketActivities);
      if(modulestate){
        this.modulesHours = this.modulesHours.filter(x => x.totalHours > 0)
      }
    };

    if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.ticketActivityService.getList().subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }

    if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerTicketActivityList(res.data.partnerId).subscribe((res: any) => {
          let filteredItems = res.data;
          filterFunction(filteredItems);
        });
      });
    }
    if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.ticketActivityService.restrictedTicketActivities(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }
    if (this.secondLevelRestriction) {
      this.ticketActivityService.getUsersTicketActivities(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }
  }
  manuelFromDate: Date;
  manuelToDate: Date;

  changeDatesByManuel() {
    this.manuelFromDate = new Date();

    if (this.fromDate) {
      this.manuelFromDate = new Date(this.fromDate);
    }

    if (this.toDate === null) {
      this.toDate = new Date(this.fromDate);
      this.toDate.setHours(23, 59, 59);
      this.manuelToDate = new Date(this.toDate);
    }

    if (this.fromDateExample) {
      this.formatFromDate(this.manuelFromDate);
    }
    if (this.toDateExample) {
      this.formatToDate(this.manuelToDate);
    }
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {

      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);


    if (this.fromDate && this.toDate) {
      let filterFunction = (items: any) => {
        this.ticketActivities = items.filter(item =>
          new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate
        );
      };

      if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.ticketActivityService.getList().subscribe((items: any) => {
          filterFunction(items.data);
        });
      }

      if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
          this.partnerUserService.getMyPartnerTicketActivityList(res.data.partnerId).subscribe((res: any) => {
            filterFunction(res.data);
          });
        });
      }

      if (this.firstLevelRestriction && !this.secondLevelRestriction) {
        this.ticketActivityService.restrictedTicketActivities(this.myId).subscribe((items: any) => {
          filterFunction(items.data);
        });
      }

      if (this.secondLevelRestriction) {
        this.ticketActivityService.getUsersTicketActivities(this.myId).subscribe((items: any) => {
          filterFunction(items.data);
        });
      }
    }

    this.tableByFiltersId();
  }

  formatFromDate(date: any) {
    if (date && date.getFullYear() && date.getMonth() >= 0 && date.getDate()) {
      if (date.getFullYear() >= 1000) {
        this.fromDateExample = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      }
    }
  }

  formatToDate(date: any) {
    if (date && date.getFullYear() && date.getMonth() >= 0 && date.getDate()) {
      if (date.getFullYear() >= 1000) {
        this.toDateExample = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      }
    }
  }

  // formatDate(date: string): string {
  //   const parts = date.split('-');
  //   if (parts.length === 3) {
  //     const [year, month, day] = parts;
  //     if (year.length === 4 && Number(day) > 0 && Number(month) > 0 && Number(year) > 0) {
  //       this.fromDateExample = new NgbDate(Number(year), Number(month), Number(day));
  //       return `${year}-${month}-${day}`;
  //     }
  //   }
  //   // return date; // Dönüştürme işlemi başarısız olursa orijinal değeri geri döndürün.
  // }
  // tableByDates() {
  //   this.fromDate = new Date(this.fromDateExample.year, this.fromDateExample.month - 1, this.fromDateExample.day, 0, 0, 0);

  //   if (this.toDateExample == null)
  //     this.toDate = new Date(this.fromDateExample.year, this.fromDateExample.month - 1, this.fromDateExample.day, 23, 59, 59);
  //   else
  //     this.toDate = new Date(this.toDateExample.year, this.toDateExample.month - 1, this.toDateExample.day, 23, 59, 59);

  //   if (!this.fromDate || !this.toDate) {
  //     this.list();
  //   } else {
  //     let filterFunction = (items: any) => {
  //       this.ticketActivities = items.filter(item =>
  //         new Date(item.startDate) >= this.fromDate &&
  //         new Date(item.startDate) <= this.toDate
  //       );
  //     };

  //     if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
  //       (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
  //       this.ticketActivityService.getList().subscribe((items: any) => {
  //         filterFunction(items.data);
  //       });
  //     }

  //     if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
  //       (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
  //       this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
  //         this.partnerUserService.getMyPartnerTicketActivityList(res.data.partnerId).subscribe((res: any) => {
  //           filterFunction(res.data);
  //         });
  //       });
  //     }
  //     if (this.firstLevelRestriction && !this.secondLevelRestriction) {
  //       this.ticketActivityService.restrictedTicketActivities(this.myId).subscribe((items: any) => {
  //         filterFunction(items.data);
  //       });
  //     }
  //     if (this.secondLevelRestriction) {
  //       this.ticketActivityService.getUsersTicketActivities(this.myId).subscribe((items: any) => {
  //         filterFunction(items.data);
  //       });
  //     }
  //   }
  //   this.tableByFiltersId();
  // }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  tableByFiltersIdForEffort() {
    let filterFunction = (filteredItems: any) => {
      if (this.selectedUsersForEffort.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsersForEffort.includes(item.userId));
      }

      if (this.selectedEffortStatuses.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedEffortStatuses.includes(item.statusId));
      }


      if (this.fromDateForEffort || this.toDateForEffort) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDateForEffort) &&
          new Date(item.startDate) <= new Date(this.toDateForEffort)
        );
      }
      this.efforts = filteredItems;
    };

    if ((this.userRole === "Admin" || this.userRole === "Consultant" || this.userRole === "ConsultantUser") &&
      (this.firstLevelRestrictionForEffort === false && this.secondLevelRestrictionForEffort === false)) {
      this.effortService.getList().subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }

    if ((this.userRole === "Customer" || this.userRole === "CustomerUser") &&
      (this.firstLevelRestrictionForEffort === false && this.secondLevelRestrictionForEffort === false)) {
      this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerEffortList(res.data.partnerId).subscribe((res: any) => {
          let filteredItems = res.data;
          filterFunction(filteredItems);
        });
      });
    }
    if (this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
      this.effortService.restrictedEfforts(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }
    if (this.secondLevelRestrictionForEffort) {
      this.effortService.getUsersEfforts(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }
  }

  tableByDatesForEffort() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExampleForEffort instanceof NgbDate) {
      tempToDate = { day: this.fromDateExampleForEffort.day, month: this.fromDateExampleForEffort.month - 1, year: this.fromDateExampleForEffort.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExampleForEffort, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDateForEffort = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExampleForEffort == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExampleForEffort instanceof NgbDate) {
      tempToDate = { day: this.toDateExampleForEffort.day, month: this.toDateExampleForEffort.month - 1, year: this.toDateExampleForEffort.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExampleForEffort, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDateForEffort = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

    if (!this.fromDateForEffort || !this.toDateForEffort) {
      this.list();
    } else {
      let filterFunction = (items: any) => {
        this.efforts = items.filter(item =>
          new Date(item.startDate) >= this.fromDateForEffort &&
          new Date(item.startDate) <= this.toDateForEffort
        );
        this.tableByFiltersIdForEffort();
      };

      if ((this.userRole === "Admin" || this.userRole === "Consultant" || this.userRole === "ConsultantUser") &&
        (this.firstLevelRestrictionForEffort === false && this.secondLevelRestrictionForEffort === false)) {
        this.effortService.getList().subscribe((items: any) => {
          filterFunction(items.data);
        });
      }

      if ((this.userRole === "Customer" || this.userRole === "CustomerUser") &&
        (this.firstLevelRestrictionForEffort === false && this.secondLevelRestrictionForEffort === false)) {
        this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
          this.partnerUserService.getMyPartnerEffortList(res.data.partnerId).subscribe((res: any) => {
            filterFunction(res.data);
          });
        });
      }
      if (this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
        this.effortService.restrictedEfforts(this.myId).subscribe((items: any) => {
          filterFunction(items.data);
        });
      }
      if (this.secondLevelRestrictionForEffort) {
        this.effortService.getUsersEfforts(this.myId).subscribe((items: any) => {
          filterFunction(items.data);
        });
      }
    }
    this.tableByFiltersIdForEffort();
  }



  isHoveredForEffort(date: NgbDate) {
    return (
      this.fromDateExampleForEffort && !this.toDateExampleForEffort && this.hoveredDateForEffort && date.after(this.fromDateExampleForEffort) && date.before(this.hoveredDateForEffort)
    );
  }
  isInsideForEffort(date: NgbDate) {
    return this.toDateExampleForEffort && date.after(this.fromDateExampleForEffort) && date.before(this.toDateExampleForEffort);
  }

  isRangeForEffort(date: NgbDate) {
    return (
      date.equals(this.fromDateExampleForEffort) ||
      (this.toDateExampleForEffort && date.equals(this.toDateExampleForEffort)) ||
      this.isInsideForEffort(date) ||
      this.isHoveredForEffort(date)
    );
  }


  onDateSelectionForEffort(date: NgbDate) {
    if (!this.fromDateExampleForEffort && !this.toDateExampleForEffort) {
      this.fromDateExampleForEffort = date;
    } else if (this.fromDateExampleForEffort && !this.toDateExampleForEffort && date && date.after(this.fromDateExampleForEffort)) {
      this.toDateExampleForEffort = date;
    } else {
      this.toDateExampleForEffort = null;
      this.fromDateExampleForEffort = date;
    }
    this.tableByDatesForEffort();
  }

  exportExcelActivities() {
    let element = document.getElementById("excel-table-ticketactivity");
    let title = "Aktiviteler";
    this.helperService.exportExcel(element, title);
  }
  exportExcelEfforts() {
    let element = document.getElementById("excel-table-effort");
    let title = "Onaylar";
    this.helperService.exportExcel(element, title);
  }

}
